$primary: #BCC8C1;
$secondary: #356362;
$nav-height: 2.3em;
$page-width: 70%;

@import "reset";
@import "typography";
@import "navigation";
@import "slider";
@import "slick-slider";
@import "main";
@import "contact";
@import "responsive";
