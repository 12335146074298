em {
  font-weight: bold;
}

strong {
  font-weight: bold;
  color: $secondary;
}

a {
  color: $secondary;
  text-decoration: none;
}

.page-width {
  width: $page-width;
  max-width: 1000px;
  margin: auto;
}

.text-center {
  text-align: center;
}

.contact-details {
  list-style: none;
  padding: 1em 0 1em 0;
  width: $page-width;
  margin: auto;
  font-size: 0.8em;

  svg {
    fill: $secondary;
    height: 1.3em;
    width: 1.3em;
    vertical-align: middle;
  }

  li {
    display: inline-block;
    width: 33%;
    float: left;
    color: $secondary;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}

.logo {
  display: block;
  margin: 1.2em auto 1.2em auto;
  height: 6em;
  fill: $secondary;
  clear: both;
}

.menu-toggle-button {
  display: none;
}

.responsive-menu {
  display: none;
}

.banner {
  border-bottom: $secondary 3em solid;
  img {
    width: 100%;
    display: block;
  }
}

.content {
  clear: both;
  margin: 1.5em auto;
}

.left {
  display: inline-block;
  float: left;
  clear: both;
  margin: 3em 0 3em 0;
}

.right {
  display: inline-block;
  float: right;
  margin: 3em 0 3em 0;
}

.text {
  width: 60%;
  vertical-align: middle;
}

.lettings-pic {
  text-align: center;
  img {
    margin: 1em;
  }
}

.circular-pic {
  vertical-align: middle;
  width: 300px;
	height: 300px;
	border-radius: 150px;
	-webkit-border-radius: 150px;
	-moz-border-radius: 150px;
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, .8);
  	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .8);
  	-moz-box-shadow: 0 0 8px rgba(0, 0, 0, .8);
  }
}

.lettings {
  background-image: url('/assets/farmhouse300x300.jpg');
}

.composting {
  background-image: url('/assets/mulch300x300.jpg');
}

.islip {
  background-image: url('/assets/300x300islip.jpg');
}

.all4s {
  background-image: url('/assets/car300x300.jpg');
}

.office {
  background-image: url('/assets/office300x300.jpg');
}

footer {
  clear: both;
  text-align: center;
  padding: 2em 0 1em 0;
  background-color: $secondary;
  color: #fff;
}

.horizontal-list-plain {
  li {
    display: inline-block;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

.cc_container .cc_btn, .cc_container .cc_btn:visited {
  background-color: $secondary !important;
  color: #fff !important;
}

.cc_container {
  padding: 0.4em !important;
}
