@media screen and (max-width: 1280px) {
  .page-width {
    width: 90%;
  }
}

@media screen and (max-width: 960px) {
  /* Mini reset */
  .left, .right {
    float: none;
    clear: none;
    margin: 0;
  }
  /* Mini reset */

  .table-switch-responsive {
    display: table;
    margin-bottom: 1.5em;
    .left {
      display: table-footer-group;
      h3 {
        margin-top: 0.8em;
      }
    }
    .right {
      display: block;
      margin: auto;
    }
  }

  .normal-responsive {
    margin-bottom: 1.5em;
    .left {
      display: block;
      margin: auto;
    }
    .right {
      width: 100%;
      h3 {
        margin-top: 0.8em;
      }
    }
  }
}

@media screen and (max-width: 765px) {
  .contact-details {
    padding-bottom: 0;
    li {
      display: block;
      float: none;
      text-align: center;
      width: 100%;
      &:nth-child(1), &:nth-child(3) {
        text-align: center;
      }
    }
  }

  .logo {
    margin-top: 0.4em;
    height: 8em;
  }

  .menu-toggle-button {
    display: block;
    background-color: $secondary;
    text-align: center;
    text-transform: uppercase;
    a {
      color: #fff;
      span {
        vertical-align: middle;
        display: table-cell;
        padding: 0.8em;
      }
    }
  }


  .normal-menu {
    display: none;
  }

  .responsive-menu {
    background-color: $primary;
    ul {
      text-align: center;
      border-bottom: .3em solid $secondary;
      li {
        padding: .5em 0 .5em 0;
        &:hover {
          background-color: $secondary !important;
        }
        a {
          color: #fff;

        }
      }
    }
  }

  #current {
    background-color: $primary;
  }

  .menu-toggle-icon {
    height: 1.5em;
    stroke: #fff;
  }
}
